import { StepsTheme as Steps } from "chakra-ui-steps";

const theme = {
  fonts: {
    heading: "Poppins, sans-serif",
    body: "Poppins, sans-serif",
  },
  components: {
    Steps,
  },
  styles: {
    global: {
      /* Headings */
      h1: {
        fontSize: "36px" /* Ukuran besar untuk h1 */,
        fontWeight: "bold",
      },
      h2: {
        fontSize: "30px" /* Heading lebih kecil */,
        fontWeight: "bold",
      },

      h3: {
        fontSize: "24px",
        fontWeight: "bold",
      },

      h4: {
        fontSize: "20px",
        fontWeight: "bold",
      },

      h5: {
        fontSize: "18px",
        fontWeight: "bold",
      },

      h6: {
        fontSize: "16px",
        fontWeight: "bold",
      },

      /* Paragraphs */
      p: {
        fontSize: "16px" /* Default ukuran teks paragraf */,
        lineHeight: 1.5,
      },
    },
  },
  initialColorMode: "light",
  useSystemColorMode: false,
};

export default theme;
